import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { IRootState } from "../reducers/root";
import { LeftPanelParams } from "../../model/LeftPanelParams";
import { QueryParam3 } from "../../model/CommonQueryParams";
import { APP_VERSION } from "../../constants/common";
import { selectIxsRef } from "../selectors/selectIxsRef";
import getHeaders from "../../helpers/getHeaders";
import GetResponseJson from "../../helpers/getResponseJson";
import { PracListResultMobile } from "../../model/WorksheetModels";
import { IWorksheetAttachment } from "../reducers/worksheet";

const DOMAIN = "worksheet";
const DATA_ADDRESS = "/breeze/IpoData/GetMonthByLeftPanelParamsSelfService";

export const fetchWorksheetDataForMonth = createAsyncThunk<
    PracListResultMobile,
    LeftPanelParams,
    { state: IRootState }
>(`${DOMAIN}/fetchWorksheetDataForMonth`, async function (arg, api) {
    arg.SelectedIxsRef = selectIxsRef(api.getState());
    const requestOptions: RequestInit = {
        method: "POST",
        headers: getHeaders(api),
        body: JSON.stringify(arg),
    };
    return await GetResponseJson(api, DATA_ADDRESS, requestOptions);
});

export const fetchWorksheetDataForPreviousMonth = createAsyncThunk<
    PracListResultMobile,
    { params: LeftPanelParams; computeTo: number },
    { state: IRootState }
>(`${DOMAIN}/fetchWorksheetDataForPreviousMonth`, async function (arg, api) {
    arg.params.SelectedIxsRef = selectIxsRef(api.getState());
    const arg1 = {
        ...arg.params,
        StartDate: arg.params.StartDate?.addMonths(-1),
        EndDate:
            arg.computeTo === 2 &&
            new Date().getFullYear() === arg.params.StartDate?.addMonths(-1).getFullYear() &&
            new Date().getMonth() === arg.params.StartDate.addMonths(-1).getMonth()
                ? new Date().getStartOfDay()
                : arg.params.StartDate,
    };
    const requestOptions: RequestInit = {
        method: "POST",
        headers: getHeaders(api),
        body: JSON.stringify(arg1),
    };
    return await GetResponseJson(api, DATA_ADDRESS, requestOptions);
});

export const fetchWorksheetDataForNextMonth = createAsyncThunk<
    PracListResultMobile,
    { params: LeftPanelParams; computeTo: number },
    { state: IRootState }
>(`${DOMAIN}/fetchWorksheetDataForNextMonth`, async function (arg, api) {
    arg.params.SelectedIxsRef = selectIxsRef(api.getState());
    const arg1 = {
        ...arg.params,
        StartDate: arg.params.StartDate?.addMonths(1),
        EndDate:
            arg.computeTo === 2 &&
            new Date().getFullYear() === arg.params.StartDate?.addMonths(1).getFullYear() &&
            new Date().getMonth() === arg.params.StartDate.addMonths(1).getMonth()
                ? new Date().getStartOfDay()
                : arg.params.StartDate?.addMonths(2),
    };
    const requestOptions: RequestInit = {
        method: "POST",
        headers: getHeaders(api),
        body: JSON.stringify(arg1),
    };
    return await GetResponseJson(api, DATA_ADDRESS, requestOptions);
});

export const scrollWorksheetPrevious = createAction(`${DOMAIN}/scrollWorksheetPrevious`);

export const scrollWorksheetNext = createAction(`${DOMAIN}/scrollWorksheetNext`);

export const resetWorksheetData = createAction(`${DOMAIN}/resetWorksheetData`);

export const getParamsForWorksheet = createAsyncThunk<{}, void, { state: IRootState }>(
    `${DOMAIN}/getParamsForWorksheet`,
    async (arg, api) => {
        const requestOptions: RequestInit = {
            method: "GET",
            headers: getHeaders(api),
        };
        return GetResponseJson(api, "/breeze/IpoData/SelfService_GetPListParams", requestOptions);
    }
);

export const signWorksheet = createAsyncThunk<{}, QueryParam3<string, Date, string>, { state: IRootState }>(
    `${DOMAIN}/signWorksheet`,
    async (arg, api) => {
        arg.Value3 = APP_VERSION;
        let reqBody: string = JSON.stringify(arg);
        const requestOptions: RequestInit = {
            method: "POST",
            headers: getHeaders(api),
            body: reqBody,
        };
        return GetResponseJson(api, "/breeze/IpoData/SelfService_SignWorksheet", requestOptions);
    }
);

export const getAttachments = createAsyncThunk<IWorksheetAttachment[], { date: Date }, { state: IRootState }>(
    `${DOMAIN}/getAttachments`,
    async (arg, api) => {
        let reqBody: string = JSON.stringify({
            SelectedIxsRef: selectIxsRef(api.getState()),
            StartDate: arg.date,
        });
        const requestOptions: RequestInit = {
            method: "POST",
            headers: getHeaders(api),
            body: reqBody,
        };
        return GetResponseJson(api, "/breeze/IpoData/GetWorkSheetAttachments", requestOptions);
    }
);

export const uploadAttachment = createAsyncThunk<
    {},
    { file: Blob; filename: string; date: Date; name: string; note: string },
    { state: IRootState }
>(`${DOMAIN}/uploadAttachment`, async (arg, api) => {
    const formData = new FormData();
    const ext = arg.filename.split(".").slice(-1);
    formData.append("file", arg.file, arg.name + "." + ext);
    const requestOptions: RequestInit = {
        method: "POST",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${api.getState().session.user.token?.access_token}`,
        },
        signal: api.signal,
        body: formData,
    };
    const params = new URLSearchParams({
        r: selectIxsRef(api.getState()),
        m: (arg.date.getMonth() + 1).toString(),
        y: arg.date.getFullYear().toString(),
        n: arg.note,
    });
    return GetResponseJson(api, "/breeze/IpoData/UploadAttachment?" + params, requestOptions);
});

export const resetUploadAttachment = createAction(`${DOMAIN}/resetUploadAttachment`);
