import { createAsyncThunk } from "@reduxjs/toolkit";
import { IAccount } from "../../../model/IAccount";
import { QueryParam2 } from "../../../model/CommonQueryParams";
import { IRootState } from "../../reducers/root";

const DOMAIN = "dropdowns/accounts";

export const fetchAccounts = createAsyncThunk<IAccount[], QueryParam2<boolean, boolean>, { state: IRootState }>(
    `${DOMAIN}/fetch`,
    async (arg, api) => {
        const requestOptions: RequestInit = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${api.getState().session.user?.token?.access_token}`,
            },
            body: JSON.stringify({
                Value: api.getState().localization.language.code,
                Value2: arg.Value2,
            }),
            signal: api.signal,
        };

        const action: string = arg.Value
            ? "/breeze/IpoData/SelfService_GetIpocacsHoliday"
            : "/breeze/IpoData/SelfService_GetIpocacsNewRequest";

        const response = await fetch(api.getState().config.config?.endpoint + action, requestOptions);

        const data = (await response.json()) as unknown[];

        if (response.status === 200) {
            return data.map(
                (account: any) =>
                    ({
                        id: account.ID_UCET,
                        value: account.NAZEV,
                        abs: account.ZKRATKA,
                    } as IAccount)
            );
        } else return api.rejectWithValue(data);
    }
);
