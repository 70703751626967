import { ReportsListItems } from "../model/ReportItem";

/*
Params:
1 - PersonsDropdown,
2 - FromDate,
3 - ToDate,
4 - HolidaysDropdown,
5 - MonthPicker od,
6 - MonthPicker do,
7 - Error source,
8 - AccountDropdown,
9 - Detailed output,
10 - Show breaks,
11 - Show Intrevals,
12 - Show codes
*/

export const reportsListItems: ReadonlyArray<ReportsListItems> = [
    {
        id: 1,
        shortname: "VacationPerson",
        longname: "ANeTReportsLibrary.DovolenaStatistika.DovolenaZustatekOsoba",
        neededParams: [1, 2],
        paramsRestristrions: ["pstypixsref", "pstypdatumod"],
        permissions: "ReportsSelfServiceApp_VacationRemaining",
    },
    {
        id: 2,
        shortname: "VacationStatistiscsPerson",
        longname: "ANeTReportsLibrary.DovolenaStatistika.DovolenaCerpaniOsoba",
        neededParams: [1, 3, 4],
        paramsRestristrions: ["pstypixsref", "pstypholiday", "pstypaktualnirok"],
        permissions: "ReportsSelfServiceApp_VacationStatistics",
    },
    {
        id: 3,
        shortname: "Worksheet",
        longname: "ANeT.ReportsLibrary.WorkSheet.ReportWorkSheet, ANeTReportsLibrary",
        neededParams: [1, 13, 10, 11],
        paramsRestristrions: ["IxsRef", "dateRef", "DateTo", "zobrPrestavky", "zobrIntervaly", "accessIxsRef"],
        permissions: "ReportsSelfServiceApp_WorkSheet",
    },
    {
        id: 4,
        shortname: "Plan",
        longname: "ANeT.ReportsLibrary.PlanApp.ReportPlanOsoby, ANeTReportsLibrary",
        neededParams: [1, 13, 12],
        paramsRestristrions: [
            "ixsRef",
            "ixsRefs",
            "dateStart",
            "dateEnd",
            "printCodes",
            "printChanges",
            "specialSlots",
            "accessIxsRef",
            "pstypuserid",
        ],
        permissions: "ReportsSelfServiceApp_Plan",
    },
    {
        id: 5,
        shortname: "MissingPassesErrors",
        longname: "ANeTReportsLibrary.StatistikaChyb.ChybyChybejiciPruchodyOsoba",
        neededParams: [1, 2, 3],
        paramsRestristrions: ["ixsref", "datumod", "datumdo"],
        permissions: "ReportsSelfServiceApp_MissingPasses",
    },
    {
        id: 6,
        shortname: "SyntaxErrors",
        longname: "ANeTReportsLibrary.StatistikaChyb.ChybyChybySyntaxeOsoba",
        neededParams: [1, 2, 3],
        paramsRestristrions: ["ixsref", "datumod", "datumdo"],
        permissions: "ReportsSelfServiceApp_ErrorSyntax",
    },
    {
        id: 7,
        shortname: "ZPErrors",
        longname: "ANeTReportsLibrary.StatistikaChyb.ChybyPoruseniZPOsoba",
        neededParams: [1, 2, 3, 7],
        paramsRestristrions: ["ixsref", "datumod", "datumdo", "zdroj"],
        permissions: "ReportsSelfServiceApp_ErrorZP",
    },
    {
        id: 8,
        shortname: "PotencialOvertimeErrors",
        longname: "ANeTReportsLibrary.StatistikaChyb.ChybyPotencialniPrescasyOsoba",
        neededParams: [1, 2, 3],
        paramsRestristrions: ["ixsref", "datumod", "datumdo"],
        permissions: "ReportsSelfServiceApp_PotencialOvertimes",
    },
    {
        id: 9,
        shortname: "LateArrivalErrors",
        longname: "ANeTReportsLibrary.StatistikaChyb.ChybyPozdniPrichodyPredcasneOdchodyOsoba",
        neededParams: [1, 2, 3],
        paramsRestristrions: ["ixsref", "datumod", "datumdo"],
        permissions: "ReportsSelfServiceApp_LatePasses",
    },
    {
        id: 10,
        shortname: "FirstArrivalsErrors",
        longname: "ANeTReportsLibrary.StatistikaChyb.ChybyPrvniPrichodyOsoba",
        neededParams: [1, 2, 3],
        paramsRestristrions: ["ixsref", "datumod", "datumdo"],
        permissions: "ReportsSelfServiceApp_FirstLastPasses",
    },
    {
        id: 11,
        shortname: "MissedShiftsErrors",
        longname: "ANeTReportsLibrary.StatistikaChyb.ChybyZameskaneSmenyOsoba",
        neededParams: [1, 2, 3],
        paramsRestristrions: ["ixsref", "datumod", "datumdo"],
        permissions: "ReportsSelfServiceApp_MissedShifts",
    },
    {
        id: 12,
        shortname: "EvidenceZP",
        longname: "ANeTReportsLibrary.EvidenceDleZP.EvidenceDleZPOsoba",
        neededParams: [1, 2, 3],
        paramsRestristrions: ["ixsref", "datumod", "datumdo"],
        permissions: "ReportsSelfServiceApp_EvidenceZP",
    },
    {
        id: 13,
        shortname: "DailyAccounts",
        longname: "ANeTReportsLibrary.DenUctyPrehled.DenUctyPrehledOsoba",
        neededParams: [1, 2, 3, 8],
        paramsRestristrions: ["pstypixsref", "pstypdatumod", "pstypdatumdo", "pstypiducet"],
        permissions: "ReportsSelfServiceApp_DailyAccounts",
    },
    {
        id: 14,
        shortname: "Overtimes",
        longname: "ANeT.ReportsLibrary.PrehledPrescasu.PrescasyOsoba",
        neededParams: [1, 2, 3],
        paramsRestristrions: ["ixsRef", "dateFrom", "dateTo"],
        permissions: "ReportsSelfServiceApp_Overtimes",
    },
    {
        id: 15,
        shortname: "BalanceStatistics",
        longname: "ANeTReportsLibrary.StatistikaSalda.SaldoMesicOsoba",
        neededParams: [1, 5, 6, 9],
        paramsRestristrions: ["pstypixsref", "pstypmesicod", "pstypmesicdo", "pstypbooleanuni"],
        permissions: "ReportsSelfServiceApp_MonthStatBalance",
    },
];
