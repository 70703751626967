import React, { useEffect, PropsWithChildren } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import NotFoundPage from "./pages/404";
import Dashboard from "./pages/Dashboard/Dashboard";
import MyRequests from "./pages/Requests/MyRequests/MyRequests";
import RequestsForAprooval from "./pages/Requests/RequestsForAprooval/RequestsForAprooval";
import PersonalCard from "./pages/PersonalCard/PersonalCard";
import MyPlan from "./pages/Plan/My/MyPlan";
import Unavailability from "./pages/Unavailability/Unavailability";
import Worksheet from "./pages/Worksheet/Worksheet";
import Timeline from "./pages/testing/Timeline";
import ReportViewer from "./pages/ReportViewer/ReportViewer";
import { StackTestPage } from "./pages/testing/Stack";
import { checkLicense, fetchStartup } from "./redux/actions/app";
import { fetchConfig } from "./redux/actions/config";
import DepartmentPlan from "./pages/Plan/Department/DepartmentPlan";
import { unwrapResult } from "@reduxjs/toolkit";
import { LocalizationProvider } from "@progress/kendo-react-intl";
import { selectLanguage } from "./redux/selectors/selectLanguage";
import StartUp from "./pages/startup/StartUp";
import Terminal from "./pages/Terminal/Terminal";
import LastPasses from "./pages/LastPasses/LastPasses";
import { ErrorsManager } from "./components/debug/ErrorsManager";
import { useAppDispatch } from "./hooks/useAppDispatch";
import { useAppSelector } from "./hooks/useAppSelector";
import { tryLoginAutomatically, fetchUserLanguage, redirectToDelegatedLogout, logout } from "./redux/actions/user";
import WorksheetState from "./pages/WorsksheetState/WorksheetState";
import Presence from "./pages/Presence/Presence";
import { languages } from "./constants/languages";
import { localizationSlice } from "./redux/slices/localization";
import { usePermissions } from "./hooks/usePermissions";
import { permissions } from "./constants/permissions";
import PlanState from "./pages/PlanState/PlanState";
import PasswordChange from "./pages/PasswordChange/PasswordChange";
import { fetchUserList } from "./redux/actions/userList";
import Help from "./pages/Help/Help";
import { ResetTimer } from "./utils/Timer";
import Overtime from "./pages/Overtime/Overtime";

function App() {
    const dispatch = useAppDispatch();

    const isAuthenticated = useAppSelector(s => s.session.user.isAuthenticated);
    const duration = useAppSelector(s => s.config.config.autoLogoutMinutes);

    useEffect(() => {
        dispatch(fetchConfig())
            .then(unwrapResult)
            .then(() => dispatch(fetchStartup()))
            .then(unwrapResult)
            .then(payload => (payload.isAppEnabled === true ? dispatch(tryLoginAutomatically()) : null));
    }, [dispatch]);

    useEffect(() => {
        if (isAuthenticated) {
            const autologout = () => {
                dispatch(redirectToDelegatedLogout());
                dispatch(logout());
            };
            ResetTimer(duration, autologout);
            document.addEventListener("mousedown", () => ResetTimer(duration, autologout));
            document.addEventListener("touchstart", () => ResetTimer(duration, autologout));
            document.addEventListener("keydown", () => ResetTimer(duration, autologout));
            document.addEventListener("wheel", () => ResetTimer(duration, autologout));
        } else ResetTimer(0, () => {});
    }, [dispatch, isAuthenticated, duration]);

    const locale = useAppSelector(s => selectLanguage(s).code);
    const perm = usePermissions();

    const isApprovablePlnunava = useAppSelector(s => s.session.dashboard.data?.isApprovablePlnunava);
    return (
        <LocalizationProvider language={locale}>
            <HashRouter>
                <Switch>
                    <Route exact path="/testing/timeline" component={Timeline} />
                    <Route exact path="/testing/stack" component={StackTestPage} />
                    <Route>
                        <AuthenticatedGuard
                            unauthenticated={<StartUp />}
                            passwordInvalid={<PasswordChange initial={true} />}
                        >
                            <UserLanguageLoader>
                                <Switch>
                                    <Route exact path="/">
                                        <Redirect to="/dashboard" />
                                    </Route>
                                    <Route exact path="/requests-aproove">
                                        <Redirect to="/requests-approve" />
                                    </Route>
                                    <Route exact path={["/", "/dashboard"]} component={Dashboard} />
                                    {!perm.some(per => per === permissions.plan.run) && (
                                        <Route exact path="/plan/my/:date?" component={MyPlan} />
                                    )}
                                    {!perm.some(per => per === permissions.workSheet.run) && (
                                        <Route exact path="/my-worksheet/:date?" component={Worksheet} />
                                    )}
                                    <Route exact path="/personal-card" component={PersonalCard} />
                                    {!perm.some(per => per === permissions.requestsForApproval.run) && (
                                        <Route
                                            exact
                                            path="/requests-approve/:id?"
                                            render={props => <RequestsForAprooval {...props} isRequest={true} />}
                                        />
                                    )}
                                    {!perm.some(per => per === permissions.requestsForApproval.run) && (
                                        <Route
                                            exact
                                            path="/unavailabilities-approve/:id?"
                                            render={props => <RequestsForAprooval {...props} isRequest={false} />}
                                        />
                                    )}
                                    {!perm.some(per => per === permissions.myRequests.run) && (
                                        <Route
                                            exact
                                            path="/my-requests"
                                            render={props => <MyRequests {...props} isRequest={true} />}
                                        />
                                    )}
                                    {!perm.some(per => per === permissions.reports.run) && (
                                        <Route exact path="/reports" component={ReportViewer} />
                                    )}
                                    {!perm.some(per => per === permissions.unavailabilities.run) &&
                                        !isApprovablePlnunava && (
                                            <Route exact path="/my-unavailability" component={Unavailability} />
                                        )}
                                    {!perm.some(per => per === permissions.unavailabilities.run) &&
                                        isApprovablePlnunava && (
                                            <Route
                                                exact
                                                path="/my-unavailability"
                                                render={props => <MyRequests {...props} isRequest={false} />}
                                            />
                                        )}
                                    {!perm.some(per => per === permissions.terminal.run) && (
                                        <Route exact path="/online-terminal" component={Terminal} />
                                    )}
                                    <Route exact path="/lastPasses" component={LastPasses} />
                                    {!perm.some(per => per === permissions.overtime.run) && (
                                        <Route exact path="/overtime" component={Overtime} />
                                    )}
                                    {!perm.some(per => per === permissions.departmentPlan.run) && (
                                        <Route exact path="/plan/department" component={DepartmentPlan} />
                                    )}
                                    {!perm.some(per => per === permissions.planStates.run) && (
                                        <Route exact path="/plan/states" component={PlanState} />
                                    )}
                                    {!perm.some(per => per === permissions.workSheetStates.run) && (
                                        <Route exact path="/worksheet/states" component={WorksheetState} />
                                    )}
                                    {!perm.some(per => per === permissions.presence.run) && (
                                        <Route exact path="/presence" component={Presence} />
                                    )}
                                    <Route exact path="/change-password" component={PasswordChange} />
                                    <Route exact path="/help/:anchor?" component={Help} />
                                    <Route component={NotFoundPage} />
                                </Switch>
                            </UserLanguageLoader>
                        </AuthenticatedGuard>
                    </Route>
                </Switch>
            </HashRouter>
            <ErrorsManager />
        </LocalizationProvider>
    );
}

export default App;

function AuthenticatedGuard(
    props: PropsWithChildren<{ unauthenticated: React.ReactNode; passwordInvalid: React.ReactNode }>
) {
    const isAuthenticated = useAppSelector(s => s.session.user.isAuthenticated);
    const changePassword = useAppSelector(s => s.session.user.token?.changePassword === "True");
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(checkLicense());
            dispatch(fetchUserList());
        }
    }, [isAuthenticated, dispatch]);

    if (isAuthenticated) {
        if (changePassword) {
            return <>{props.passwordInvalid}</>;
        }
        return <>{props.children}</>;
    }
    return <>{props.unauthenticated}</>;
}

function UserLanguageLoader(props: PropsWithChildren<{}>) {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchUserLanguage())
            .then(userLangCode => {
                if (userLangCode) {
                    const lang = languages.find(l => l.code === userLangCode);
                    if (lang) dispatch(localizationSlice.actions.set(lang));
                    else console.warn("User language not found in supported languages:", userLangCode);
                }
            })
            .catch(reason => console.error(reason));
    }, [dispatch]);

    return <>{props.children}</>;
}
