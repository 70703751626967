import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { IRootState } from "../reducers/root";
import { handleResponseError } from "../../helpers/handleResponseError";
import { DelegatedAuthStyle } from "../../model/app/DelegatedAuthStyle";

interface StartupResult {
    isAppEnabled: boolean;
    canRunWindowsAuth: boolean;
    isDelegatedAuthEnabled: boolean;
    delegatedAuthStyle: DelegatedAuthStyle;
    delegatedAuthCustomizableText: string;
    multiTenant: boolean;
}

export const fetchStartup = createAsyncThunk<Partial<StartupResult>, void, { state: IRootState }>(
    "app/startup/fetch",
    async (arg, api) => {
        const requestOptions: RequestInit = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
            signal: api.signal,
        };

        const response = await fetch(
            api.getState().config.config.endpoint + "/controllers/SelfService/Startup",
            requestOptions
        );

        await handleResponseError(response);

        return (await response.json()) as Partial<StartupResult>;
    }
);

export const resetNotification = createAction("app/notification/reset");

export const setCustomerCode = createAction<string>("app/setCustomerCode");

export const checkLicense = createAsyncThunk<string, void, { state: IRootState }>("app/license", async (arg, api) => {
    const requestOptions: RequestInit = {
        method: "GET",
        headers: {
            Authorization: `Bearer ${api.getState().session.user.token?.access_token}`,
        },
    };

    const response = await fetch(
        api.getState().config.config.endpoint + "/breeze/IpoData/IsSelfServiceLicenseValid",
        requestOptions
    );

    await handleResponseError(response);

    return await response.json();
});
