import { SerializedError, createReducer } from "@reduxjs/toolkit";
import { IUserListItem } from "../../model/IUserList";
import { fetchUserList, setSelectedUser } from "../actions/userList";

export interface IUserListState {
    users: IUserListItem[];
    selected: number;
    error?: SerializedError;
}

export const userListReducer = createReducer<IUserListState>(
    {
        users: [],
        selected: 0,
    },
    builder =>
        builder
            .addCase(fetchUserList.pending, (state, action) => ({
                ...state,
                error: undefined,
            }))
            .addCase(fetchUserList.fulfilled, (state, action) => ({
                ...state,
                users: action.payload.users ?? [],
                selected: action.payload.poradi,
                error: undefined,
            }))
            .addCase(fetchUserList.rejected, (state, action) => ({
                ...state,
                error: action.error,
            }))
            .addCase(setSelectedUser, (state, action) => ({
                ...state,
                selected: action.payload.selected,
            }))
);
