import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { fetchReportData, fetchReportsItems } from "../actions/reports";
import { ReportItem } from "../../model/ReportItem";

export interface IReportsState {
    isFetching?: boolean;
    data?: Uint8Array;
    error?: unknown;
}

export const reportsReducer = createReducer<IReportsState>(
    {
        isFetching: false,
    },
    builder => {
        return builder
            .addCase(fetchReportData.pending.type, (state, action) => ({
                ...state,
                isFetching: true,
            }))
            .addCase(fetchReportData.fulfilled.type, (state, action: PayloadAction<any>) => ({
                ...state,
                isFetching: false,
                data: action.payload ? parseDataToInterface(action.payload) : undefined,
            }))
            .addCase(fetchReportData.rejected, (state, action: PayloadAction<any>) => ({
                ...state,
                isFetching: false,
                data: undefined,
                error: action.payload,
            }));
    }
);

function parseDataToInterface(raw: any): Uint8Array {
    return raw.$value;
}

export interface IReportItemsState {
    isFetching?: boolean;
    data?: ReportItem[];
    error?: unknown;
}

export const reportItemsReducer = createReducer<IReportItemsState>(
    {
        isFetching: false,
    },
    builder => {
        return builder
            .addCase(fetchReportsItems.pending.type, (state, action) => ({
                ...state,
                isFetching: true,
            }))
            .addCase(fetchReportsItems.fulfilled.type, (state, action: PayloadAction<any>) => ({
                ...state,
                isFetching: false,
                data: prepareData(action.payload),
            }))
            .addCase(fetchReportsItems.rejected, (state, action: PayloadAction<any>) => ({
                ...state,
                isFetching: false,
                error: action.payload,
            }));
    }
);

function prepareData(raw: any): ReportItem[] {
    let data = raw as ReportItem[];
    let result: ReportItem[] = [];
    data.forEach(element => {
        if (element.caption.toLowerCase() !== "importované reporty") getDataRecursively(element, result);
    });
    return result;
}

function getDataRecursively(item: ReportItem, result: ReportItem[]) {
    if (item.items === null && !item.uploaded) result.push(item);
    else {
        item.items.forEach(item => {
            if (item.classname === null) {
                getDataRecursively(item, result);
            } else if (!item.uploaded) {
                result.push(item);
            }
        });
    }
}
