import { handleResponseError } from "../../helpers/handleResponseError";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { IRootState } from "../reducers/root";
import { IUserList } from "../../model/IUserList";

const DOMAIN = "UserList";

export const setSelectedUser = createAction(`${DOMAIN}/setSelectedUser`, function prepare(selected: number) {
    return { payload: { selected } };
});

export const fetchSelectedUserTicket = createAsyncThunk<string, number, { state: IRootState }>(
    `${DOMAIN}/fetchSelectedUser`,
    async (poradi, api) => {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${api.getState().session.user?.token?.access_token}`);
        const requestOptions: RequestInit = {
            method: "GET",
            headers: headers,
        };
        const response = await fetch(
            api.getState().config.config.endpoint + "/breeze/IpoData/GetAuthUserTicketSelfService?poradi=" + poradi,
            requestOptions
        );

        await handleResponseError(response);

        return await response.json();
    }
);

export const fetchUserList = createAsyncThunk<IUserList, void, { state: IRootState }>(
    `${DOMAIN}/fetchUserList`,
    async (arg, api) => {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${api.getState().session.user?.token?.access_token}`);
        const requestOptions: RequestInit = {
            method: "GET",
            headers: headers,
        };
        const response = await fetch(
            api.getState().config.config.endpoint + "/breeze/IpoData/GetUserList",
            requestOptions
        );

        await handleResponseError(response);

        return await response.json();
    }
);
