import { createReducer } from "@reduxjs/toolkit";
import { IAccount } from "../../../model/IAccount";
import { fetchAccounts } from "../../actions/dropdowns/accounts";

export interface IAccountsState {
    isFetching: boolean;
    payload: IAccount[];
    error?: unknown;
}

export const accountsReducer = createReducer<IAccountsState>(
    {
        isFetching: false,
        payload: [],
    },
    builder => {
        builder
            .addCase(fetchAccounts.pending, (state, action) => ({
                isFetching: true,
                payload: state.payload,
                error: undefined,
            }))
            .addCase(fetchAccounts.fulfilled, (state, action) => ({
                isFetching: false,
                payload: action.payload,
                error: undefined,
            }))
            .addCase(fetchAccounts.rejected, (state, action) => ({
                isFetching: false,
                payload: [],
                error: action.payload,
            }));
    }
);
