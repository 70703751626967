import React, { useEffect, useRef, useState } from "react";
import { PageLayout } from "../../components/PageLayout/PageLayout";
import { NavBar } from "../../components/NavBar/NavBar";
import styles from "./ReportViewer.module.scss";
import { DialogActionsBar, Dialog } from "@progress/kendo-react-dialogs";
import { ReportParam } from "../../model/CommonQueryParams";
import { fetchReportData, fetchReportsItems } from "../../redux/actions/reports";
import { ReportItem, ReportItemWithPermission, ReportsListItems } from "../../model/ReportItem";
import { List, ListItem, ListItemText } from "@material-ui/core";
import { Button } from "@progress/kendo-react-buttons";
import { DatePicker, DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { IntlProvider } from "@progress/kendo-react-intl";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import MonthPicker from "../../components/MonthPicker/MonthPicker";
import AccountDropdown from "../../components/AccountDropdown/AccountDropdown";
import { reportsListItems } from "../../constants/reports";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { selectUserFullname } from "../../redux/selectors/selectUserFullname";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import template from "./template.html";
import templateChrome from "./templateChrome.html";
import { useStrings } from "../../hooks/useStrings";
import { SpinnerBox } from "../../components/Spinner/SpinnerBox";

const isMobileOrTablet = window.innerWidth <= 600;
const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
const isApple =
    /iPhone/.test(navigator.userAgent) || /iPad/.test(navigator.userAgent) || /Macintosh/.test(navigator.userAgent);

export default function ReportViewer() {
    const dispatch = useAppDispatch();
    const strings = useStrings();

    const code = useAppSelector(s => s.localization.language.code);
    const ixsRef = useAppSelector(s => s.session.user.token?.sid ?? "");
    const fullName = useAppSelector(selectUserFullname);
    const fetching = useAppSelector(s => s.session.reports.isFetching);
    const data = useAppSelector(s => s.session.reports.data);
    const list = useAppSelector(s => s.session.reportItems.data);
    const isFetchingReport = useAppSelector(s => s.session.reportItems.isFetching);
    const permissions = useAppSelector(s => s.session.user.token?.userInvisibleActions ?? []);

    const [selectedReport, setSelectedReport] = useState<ReportsListItems>();
    const [dateFrom, setDateFrom] = useState(new Date().firstDayOfMonth());
    const [dateTo, setDateTo] = useState(new Date().lastDayOfMonth());
    const [selectedErrorSource, setSelectedErrorSource] = useState(strings.reports.JustPlan);
    const [extendedDetail, setExtendedDetail] = useState(false);
    const [selectedMonth, setSelectedMonth] = useState(new Date());
    const [selectedMonthFrom, setSelectedMonthFrom] = useState(new Date());
    const [selectedMonthTo, setSelectedMonthTo] = useState(new Date());
    const [selectedAccount, setSelectedAccount] = useState<number>();
    const [showIntervals, setShowIntervals] = useState(false);
    const [showBreaks, setShowBreaks] = useState(false);
    const [printCodes, setPrintCodes] = useState(false);
    const [printChanges, setPrintChanges] = useState(false);
    const [dialogVisible, setDialogVisible] = useState(false);

    const save = useRef(strings.common.Save);
    const report = useRef<ReportsListItems>();

    useEffect(() => {
        save.current = strings.common.Save;
    }, [strings]);

    useEffect(() => {
        report.current = selectedReport;
    }, [selectedReport]);

    useEffect(() => {
        dispatch(fetchReportsItems());
    }, [dispatch, code]);

    /*
    Params:
    1 - PersonsDropdown,
    2 - FromDate,
    3 - ToDate,
    4 - HolidaysDropdown,
    5 - MonthPicker od,
    6 - MonthPicker do,
    7 - Error source,
    8 - AccountDropdown,
    9 - Detailed output,
    10 - Show breaks,
    11 - Show Intrevals,
    12 - Show codes
    */
    const controlAndSetParams = (): ReportParam<string, string>[] => {
        const parameters: ReportParam<string, string>[] = [];
        selectedReport?.paramsRestristrions.forEach((param: string) => {
            const para: ReportParam<string, string> = {};
            para.Item1 = param;
            switch (param) {
                case "pstypixsref":
                case "ixsRef":
                case "ixsRefs":
                case "IxsRef":
                case "pstypuserid":
                case "accessIxsRef":
                case "ixsref": {
                    para.Item2 = ixsRef;
                    break;
                }
                case "pstypdatumod":
                case "dateRef":
                case "dateStart":
                case "dateFrom":
                case "datumod": {
                    para.Item2 = dateFrom?.getStartOfDay().toISOStringIgnoringTZ();
                    break;
                }
                case "pstypdatumdo":
                case "dateEnd":
                case "dateTo":
                case "DateTo":
                case "pstypaktualnirok":
                case "datumdo": {
                    para.Item2 = dateTo?.getStartOfDay().toISOStringIgnoringTZ();
                    break;
                }
                case "pstypmesicod": {
                    para.Item2 = selectedMonthFrom?.firstDayOfMonth().toISOStringIgnoringTZ();
                    break;
                }
                case "pstypmesicdo": {
                    para.Item2 = selectedMonthTo?.lastDayOfMonth().toISOStringIgnoringTZ();
                    break;
                }
                case "pstypholiday":
                case "pstypiducet": {
                    para.Item2 = selectedAccount?.toString();
                    break;
                }
                case "zdroj": {
                    para.Item2 = selectedErrorSource?.toString();
                    break;
                }
                case "pstypbooleanuni": {
                    para.Item2 = extendedDetail?.toString();
                    break;
                }
                case "zobrPrestavky": {
                    para.Item2 = showBreaks?.toString();
                    break;
                }
                case "zobrIntervaly": {
                    para.Item2 = showIntervals?.toString();
                    break;
                }
                case "printCodes": {
                    para.Item2 = printCodes?.toString();
                    break;
                }
                case "printChanges": {
                    para.Item2 = printChanges?.toString();
                    break;
                }
                case "specialSlots": {
                    para.Item2 = "";
                    break;
                }
            }
            parameters.push(para);
        });
        return parameters;
    };

    useEffect(() => {
        const fileName = report.current?.shortname + "_" + new Date().getFullDateString();

        const downloadPDF = () => {
            const a = document.createElement("a");
            a.href = `data:application/pdf;base64,${data}`;
            a.download = fileName;
            a.click();
        };

        const base64ToBlob = (base64: any) => {
            const binStr = atob(base64);
            const len = binStr.length;
            const arr = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                arr[i] = binStr.charCodeAt(i);
            }
            return new Blob([arr], { type: "application/pdf" });
        };

        const downloadPDFApple = () => {
            const a = document.createElement("a");
            a.href = URL.createObjectURL(base64ToBlob(data));
            a.download = fileName;
            a.target = "_blank";
            a.click();
        };

        if (data && report.current) {
            if (isApple) {
                downloadPDFApple();
            } else if (isMobileOrTablet) {
                downloadPDF();
            } else {
                const newWindow = window.open("", "_blank");
                if (newWindow) {
                    const content = (isChrome ? templateChrome : template)
                        .replaceAll("$DATA$", data.toString())
                        .replaceAll("$FILENAME$", fileName)
                        .replaceAll("$SAVE$", save.current);
                    newWindow.document.write(content);
                    newWindow.document.close();
                    newWindow.window.focus();
                }
            }
        }
        setSelectedReport(undefined);
    }, [data]);

    const saveOrOpenPDF = () => {
        const params = controlAndSetParams();
        dispatch(fetchReportData({ Value: selectedReport?.longname, Value2: params }));
    };

    const openParametersDialog = (className: string) => {
        const item = reportsListItems.find(ipo => className.startsWith(ipo.longname));
        setSelectedReport(item);
        setDialogVisible(true);
    };

    const toggleDialog = () => {
        setDialogVisible(!dialogVisible);
    };

    const handleSaving = () => {
        saveOrOpenPDF();
        toggleDialog();
    };

    const fromDateChange = (event: DatePickerChangeEvent) => {
        setDateFrom(event.value!);
        if (dateTo! < event.value!) {
            setDateTo(event.value!);
        }
    };

    const toDateChange = (event: DatePickerChangeEvent) => {
        setDateTo(event.value!);
        if (dateFrom! > event.value!) {
            setDateFrom(event.value!);
        }
    };

    const handleMonthChange = (month: Date) => {
        const startDate = month.firstDayOfMonth();
        const endDate = month.addMonths(1).firstDayOfMonth();
        setDateFrom(startDate);
        setDateTo(endDate);
        setSelectedMonth(month);
    };

    const sortDataAndAdd2StaticReports = () => {
        const reports: ReportItem[] = [];
        reports.push({
            caption: strings.reports.WorksheetTitle,
            classname: "ANeT.ReportsLibrary.WorkSheet.ReportWorkSheet, ANeTReportsLibrary",
            items: [],
        });
        reports.push({
            caption: strings.plan.PlanName,
            classname: "ANeT.ReportsLibrary.PlanApp.ReportPlanOsoby, ANeTReportsLibrary",
            items: [],
        });
        list?.forEach(element => {
            reports.push(element);
        });
        const reportsResult: ReportItemWithPermission[] = reports.map(report => ({
            ...report,
            permission: reportsListItems.find(ipo => report.classname.startsWith(ipo.longname))?.permissions ?? "",
        }));
        return reportsResult.sort((a, b) => a.caption.localeCompare(b.caption));
    };

    function ParamRow(props: React.PropsWithChildren<{ index: number; label: string }>) {
        return !!selectedReport?.neededParams.some(s => s === props.index) ? (
            <div className={styles.paramRow}>
                <label>{props.label + ":"}</label>
                {props.children}
            </div>
        ) : (
            <></>
        );
    }

    const resultItems = sortDataAndAdd2StaticReports();
    return (
        <PageLayout header={<NavBar canNavigateRoot label={strings.dashboard.Reports} menu={{ items: [] }} />}>
            <IntlProvider locale={code}>
                <div>
                    {!(isFetchingReport || fetching) && list && (
                        <List>
                            {resultItems.map(
                                (row, key) =>
                                    !permissions.includes(row.permission) && (
                                        <ListItem
                                            key={key}
                                            divider
                                            dense
                                            button
                                            onClick={() => openParametersDialog(row.classname)}
                                        >
                                            <ListItemText primary={row.caption} />
                                        </ListItem>
                                    )
                            )}
                        </List>
                    )}
                    {dialogVisible && (
                        <Dialog
                            className={styles.dialogStyle}
                            title={strings.reports.Params}
                            closeIcon={false}
                            onClose={toggleDialog}
                            width="90%"
                        >
                            <ParamRow index={1} label={strings.worksheet.NameWS}>
                                <Input readOnly value={fullName} />
                            </ParamRow>
                            <ParamRow
                                index={2}
                                label={selectedReport?.id === 1 ? strings.reports.ToDate : strings.requests.FromDate}
                            >
                                <DatePicker value={dateFrom} onChange={fromDateChange} />
                            </ParamRow>
                            <ParamRow index={3} label={strings.requests.ToDate}>
                                <DatePicker value={dateTo} onChange={toDateChange} />
                            </ParamRow>
                            <ParamRow index={4} label={strings.worksheet.AccountDefName}>
                                <AccountDropdown onlyHolidayAccounts onSelect={e => setSelectedAccount(e.id)} />
                            </ParamRow>
                            <ParamRow index={5} label={strings.reports.MonthFrom}>
                                <MonthPicker selectedMonth={selectedMonthFrom!} onPick={e => setSelectedMonthFrom(e)} />
                            </ParamRow>
                            <ParamRow index={6} label={strings.reports.MonthTo}>
                                <MonthPicker selectedMonth={selectedMonthTo!} onPick={e => setSelectedMonthTo(e)} />
                            </ParamRow>
                            <ParamRow index={7} label={strings.reports.ErrorSource}>
                                <DropDownList
                                    data={[strings.reports.JustPlan, strings.reports.JustReality, strings.reports.Both]}
                                    onChange={e => setSelectedErrorSource(e.value)}
                                    value={selectedErrorSource}
                                />
                            </ParamRow>
                            <ParamRow index={8} label={strings.worksheet.AccountDefName}>
                                <AccountDropdown onlyHolidayAccounts={false} onSelect={e => setSelectedAccount(e.id)} />
                            </ParamRow>
                            <ParamRow index={13} label={strings.reports.Month}>
                                <MonthPicker selectedMonth={selectedMonth!} onPick={handleMonthChange} />
                            </ParamRow>
                            <ParamRow index={9} label={strings.reports.Extended}>
                                <Checkbox
                                    className={styles.check}
                                    checked={extendedDetail}
                                    onChange={e => setExtendedDetail(e.value)}
                                />
                            </ParamRow>
                            <ParamRow index={10} label={strings.reports.ShowBreaks}>
                                <Checkbox
                                    className={styles.check}
                                    checked={showBreaks}
                                    onChange={e => setShowBreaks(e.value)}
                                />
                            </ParamRow>
                            <ParamRow index={11} label={strings.reports.ShowIntervals}>
                                <Checkbox
                                    className={styles.check}
                                    checked={showIntervals}
                                    onChange={e => setShowIntervals(e.value)}
                                />
                            </ParamRow>
                            <ParamRow index={12} label={strings.reports.Codes}>
                                <Checkbox
                                    className={styles.check}
                                    checked={printCodes}
                                    onChange={e => setPrintCodes(e.value)}
                                />
                            </ParamRow>
                            <ParamRow index={13} label={strings.reports.PrintChanges}>
                                <Checkbox
                                    className={styles.check}
                                    checked={printChanges}
                                    onChange={e => setPrintChanges(e.value)}
                                />
                            </ParamRow>
                            <DialogActionsBar>
                                <Button className="k-button" onClick={toggleDialog} style={{ color: "black" }}>
                                    {strings.common.Cancel}
                                </Button>
                                <Button
                                    autoFocus
                                    className="k-button"
                                    onClick={handleSaving}
                                    style={{ color: "green" }}
                                >
                                    {isMobileOrTablet ? strings.common.Save : strings.reports.LoadReport}
                                </Button>
                            </DialogActionsBar>
                        </Dialog>
                    )}
                    {(isFetchingReport || fetching) && <SpinnerBox stretchToWindow />}
                </div>
            </IntlProvider>
        </PageLayout>
    );
}
