import React, { useEffect, useState } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { getAttachments } from "../../redux/actions/worksheet";
import { SpinnerBox } from "../../components/Spinner/SpinnerBox";
import { Button } from "@progress/kendo-react-buttons";
import { IWorksheetAttachment } from "../../redux/reducers/worksheet";
import { List, ListItem, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import styles from "./AttachmentsDialog.module.scss";
import PictureDialog from "./PictureDialog";

function getSize(n: number) {
    if (n < 1024) return n + "B";
    n = n / 1024;
    if (n < 1024) return n.toFixed(1) + "KB";
    n = n / 1024;
    if (n < 1024) return n.toFixed(1) + "MB";
    n = n / 1024;
    return n.toFixed(1) + "GB";
}

export default function AttachmentsDialog(props: { onClose: () => void; month: Date }) {
    const isLoading = useAppSelector(s => s.session.worksheetAttachment.isLoading);
    const strings = useAppSelector(s => s.localization.strings);
    const dispatch = useAppDispatch();
    const [pictureSrc, setPictureSrc] = useState("");
    const [pictureFile, setPictureFile] = useState<File | undefined>(undefined);
    const [pictureDialogVisible, setPictureDialogVisible] = useState(false);
    const attachemnts = useAppSelector(s => s.session.worksheetAttachment.attachments);
    const token = useAppSelector(s => s.session.user.token?.access_token);
    const endpoint = useAppSelector(s => s.config.config.endpoint);
    const ixsref = useAppSelector(s =>
        s.session.user.token?.sidSubst !== "" ? s.session.user.token?.sidSubst : s.session.user.token?.sid
    );

    useEffect(() => {
        if (!pictureDialogVisible) {
            const date = new Date(props.month.getFullYear(), props.month.getMonth(), 1);
            dispatch(getAttachments({ date: date }));
        }
    }, [dispatch, props.month, pictureDialogVisible]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target?.files?.[0];
        const reader = new FileReader();
        reader.onload = function (e) {
            setPictureSrc(e.target?.result as string);
            setPictureFile(file);
        };
        if (file !== undefined) {
            setPictureDialogVisible(true);
            setPictureSrc("");
            setPictureFile(undefined);
            reader.readAsDataURL(file);
        }
    };

    const openFile = (attachment: IWorksheetAttachment) => {
        const date = new Date(props.month.getFullYear(), props.month.getMonth(), 1);
        fetch(endpoint + "/breeze/IpoData/DownloadAttachment", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "authorization": "bearer " + token,
            },
            body: JSON.stringify({ SelectedIxsRef: ixsref, StartDate: date, SelectedIxsStr: attachment.name }),
        }).then(res => {
            res.blob().then(blob => {
                const elem = window.document.createElement("a");
                elem.href = window.URL.createObjectURL(blob);
                elem.download = attachment.name;
                document.body.appendChild(elem);
                elem.click();
                document.body.removeChild(elem);
            });
        });
    };

    return (
        <>
            <Dialog title={strings.worksheet.Attachments} onClose={props.onClose} width="90%">
                {isLoading ? (
                    <SpinnerBox />
                ) : attachemnts.length > 0 ? (
                    <List>
                        {attachemnts.map(att => {
                            return (
                                <ListItem key={att.id_att} className={styles.ellipsis}>
                                    <ListItemText
                                        primary={att.name}
                                        secondary={getSize(att.size) + (!!att.poznamka ? " - " + att.poznamka : "")}
                                    />
                                    <ListItemSecondaryAction>
                                        <Button
                                            look="flat"
                                            iconClass={"k-icon k-i-download"}
                                            onClick={() => openFile(att)}
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        })}
                    </List>
                ) : (
                    <span>{strings.worksheet.NoAttachments}</span>
                )}
                <input
                    id="f-u"
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={e => handleInputChange(e)}
                />
                <DialogActionsBar>
                    <Button
                        iconClass={"k-icon k-i-photo-camera"}
                        onClick={() => document.getElementById("f-u")?.click()}
                    >
                        {strings.worksheet.TakePicture}
                    </Button>
                </DialogActionsBar>
            </Dialog>
            {pictureDialogVisible && (
                <PictureDialog
                    src={pictureSrc}
                    file={pictureFile}
                    date={props.month}
                    onClose={() => setPictureDialogVisible(false)}
                />
            )}
        </>
    );
}
