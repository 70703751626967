import React from "react";
import { IStrings } from "../../constants/languageStrings/IStrings";
import { connect } from "react-redux";
import { IRootState } from "../../redux/reducers/root";
import { PageLayout } from "../../components/PageLayout/PageLayout";
import { NavBar } from "../../components/NavBar/NavBar";
import { fetchLastPasses } from "../../redux/actions/passesLoader";
import styles from "./LastPasses.module.scss";
import { List, ListItem, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import { LastPass } from "../../model/terminal/IPOPRT";
import { ILanguage } from "../../model/localization/ILanguage";
import { Button } from "@progress/kendo-react-buttons";
import { SerializedError } from "@reduxjs/toolkit";
import { SpinnerBox } from "../../components/Spinner/SpinnerBox";
import { ErrorNotification } from "../../components/ErrorNotification";
import { dateOptions, timeOptions } from "../../constants/dates";

class LastPasses extends React.Component<IProps, {}> {
    private getCorrectDateTimeFormat(date: Date): string {
        let datum: Date = new Date(date);
        return (
            datum.toLocaleString(this.props.lang.code, dateOptions) +
            " " +
            datum.toLocaleTimeString(this.props.lang.code, timeOptions)
        );
    }

    async componentDidMount() {
        await this.props.onLoadPasses();
    }

    private showPosition(GPS: string | undefined): void {
        if (GPS != null) {
            window.open("https://maps.google.com?q=" + GPS);
        }
    }

    public render() {
        let rows: LastPass[] = this.props.data || [];
        return (
            <PageLayout
                header={
                    <NavBar
                        canNavigateBack
                        previousPage={"/online-terminal"}
                        label={this.props.strings.lastPasses.TitleLP}
                    ></NavBar>
                }
            >
                {this.props.isFetching ? (
                    <SpinnerBox />
                ) : (
                    <>
                        {this.props.error ? (
                            <ErrorNotification error={this.props.error} />
                        ) : (
                            <List>
                                {rows.length === 0 && (
                                    <label className={styles.noPasses}>{this.props.strings.lastPasses.NoPasses}</label>
                                )}
                                {/* řádky */}
                                {rows.map((row: LastPass, key: any) => (
                                    <ListItem key={key} divider dense button>
                                        <ListItemText
                                            primary={
                                                <>
                                                    <div className={styles.firstRow}>
                                                        <label>{this.getCorrectDateTimeFormat(row.Date)}</label>
                                                        <label className={styles.typeStyle}>{row.Type}</label>
                                                    </div>
                                                    <label>{row.FullName}</label>
                                                </>
                                            }
                                            secondary={row.Note && <label>{row.Note}</label>}
                                        />
                                        <ListItemSecondaryAction>
                                            {row.GPS !== "" && row.GPS != null && (
                                                <div className={styles.icon}>
                                                    <Button
                                                        onClick={() => this.showPosition(row.GPS)}
                                                        iconClass="k-icon k-i-marker-pin-target"
                                                    ></Button>
                                                </div>
                                            )}
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))}
                            </List>
                        )}
                    </>
                )}
            </PageLayout>
        );
    }
}

interface IOwnProps {
    data: LastPass[];
    isFetching: boolean;
    error: SerializedError;
}

interface IStateProps {
    strings: IStrings;
    lang: ILanguage;
}

interface IDispatchProps {
    onLoadPasses: () => Promise<void>;
}

type IProps = IOwnProps & IStateProps & IDispatchProps;

export default connect<IStateProps, IDispatchProps, IOwnProps, IRootState>(
    state => ({
        strings: state.localization.strings,
        lang: state.localization.language,
        data: state.session.lastPasses.passes,
        isFetching: state.session.lastPasses.isFetching,
        error: state.session.lastPasses.error,
    }),
    {
        onLoadPasses: () => fetchLastPasses() as any,
    }
)(LastPasses);
