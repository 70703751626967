import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleResponseError } from "../../helpers/handleResponseError";
import { QueryParam3 } from "../../model/CommonQueryParams";
import { IPOCPT } from "../../model/terminal/IPOCPT";
import { IRootState } from "../reducers/root";

const DOMAIN = "Terminal";

export const fetchSecuredPassesDatasource = createAsyncThunk<IPOCPT[], void, { state: IRootState }>(
    `${DOMAIN}/fetchRequestsForAproovalDatasource`,
    async (arg, api) => {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${api.getState().session.user?.token?.access_token}`);
        let lang: string =
            api.getState().localization.language.code === "cs" ? "cs-CZ" : api.getState().localization.language.code;
        let reqBody = {
            Value: lang,
        };
        const requestOptions: RequestInit = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(reqBody),
        };

        const response = await fetch(
            api.getState().config.config?.endpoint + "/breeze/IpoData/GetSecuredIpocptsForMobile",
            requestOptions
        );

        await handleResponseError(response);

        let result = await response.json();
        let noPass = {
            $id: -1,
            PRITOMNOST: -1,
            PRUCHOD_TERM: api.getState().localization.strings.terminals.NoPass,
            PRUCHOD_SMER: null,
        };
        result.unshift(noPass);
        return result;
    }
);

export const insertPass = createAsyncThunk<{}, QueryParam3<string, string, string>, { state: IRootState }>(
    `${DOMAIN}/insertPass`,
    async (arg, api) => {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${api.getState().session.user?.token?.access_token}`);
        const requestOptions: RequestInit = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(arg),
        };

        const response = await fetch(
            api.getState().config.config?.endpoint + "/breeze/IpoData/InsertNewIpoprtFromOnlineTerm",
            requestOptions
        );

        await handleResponseError(response);

        return await response.json();
    }
);
