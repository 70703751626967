import React, { useState } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import styles from "./SettingsDialog.module.css";
import { Input } from "@progress/kendo-react-inputs";
import LanguageDropDown from "../../components/LanguageDropDown/LanguageDropDown";
import { updatePersonalPreferences } from "../../redux/actions/dashboard";
import { localizationSlice } from "../../redux/slices/localization";
import { selectUserFullname } from "../../redux/selectors/selectUserFullname";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useStrings } from "../../hooks/useStrings";
import { useAppDispatch } from "../../hooks/useAppDispatch";

interface IOwnProps {
    onClose?: () => void;
}

export function SettingsDialog(props: IOwnProps) {
    const [initialLanguage] = useState(useAppSelector(s => s.localization.language));

    const dispatch = useAppDispatch();

    function handleSaveClick() {
        dispatch(updatePersonalPreferences());

        props.onClose && props.onClose();
    }

    function handleCloseClick() {
        dispatch(localizationSlice.actions.set(initialLanguage));

        props.onClose && props.onClose();
    }

    const strings = useStrings();
    return (
        <Dialog title={strings.dashboard.MyAccount} closeIcon={false} width="90%">
            <FullName />
            <LanguageDropDown label={strings.common.Language} />
            <EmailInput />
            <DialogActionsBar>
                <Button onClick={handleCloseClick}>{strings.common.Close}</Button>
                <Button onClick={handleSaveClick}>{strings.common.Save}</Button>
            </DialogActionsBar>
        </Dialog>
    );
}

export default SettingsDialog;

function FullName() {
    const fullname = useAppSelector<string>(s => selectUserFullname(s));
    return <span className={styles.textPerson}>{fullname}</span>;
}

function EmailInput() {
    const strings = useStrings();
    const email = useAppSelector(s => s.session.dashboard.data?.emailForNotifications);

    return <Input type="email" disabled label={strings.dashboard.EmailForNotifications} defaultValue={email ?? " "} />;
}
