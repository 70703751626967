import React from "react";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { connect } from "react-redux";
import { IRootState } from "../../redux/reducers/root";
import { IAccount } from "../../model/IAccount";
import { IStrings } from "../../constants/languageStrings/IStrings";
import { fetchAccounts } from "../../redux/actions/dropdowns/accounts";
import { QueryParam2 } from "../../model/CommonQueryParams";

interface IOwnProps {
    onSelect?: (account: IAccount) => void;
    onlyHolidayAccounts?: boolean;
    onlyWorkflowAccounts?: boolean;
    disabled?: boolean;
}

interface IDispatchProps {
    onFetch: (e: QueryParam2<boolean, boolean>) => void;
}

interface IStateProps {
    strings: IStrings;
    accounts: IAccount[];
}

interface IState {
    account: IAccount | null;
}

type Props = IOwnProps & IDispatchProps & IStateProps;

class AccountDropdown extends React.Component<Props, IState> {
    state = {
        account: null,
    };
    async componentDidMount() {
        let param: QueryParam2<boolean, boolean> = {
            Value: this.props.onlyHolidayAccounts ?? false,
            Value2: this.props.onlyWorkflowAccounts ?? false,
        };
        this.props.onFetch && this.props.onFetch(param);
    }

    private handleChange(e: DropDownListChangeEvent): void {
        const acc = e.value as IAccount;
        this.setState({ account: acc });
        this.props.onSelect && this.props.onSelect(acc);
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.accounts !== this.props.accounts && this.props.accounts.length > 0) {
            const firstAcc = this.props.accounts[0];
            this.setState({ account: firstAcc });
            this.props.onSelect && this.props.onSelect(firstAcc);
        }
    }

    public render() {
        return (
            <React.Fragment>
                {this.props.accounts.length === 0 && <p>{this.props.strings.requests.NoAccounts}</p>}
                {this.props.accounts.length !== 0 && (
                    <DropDownList
                        data={this.props.accounts}
                        textField="value"
                        dataItemKey="id"
                        onChange={change => this.handleChange(change)}
                        value={this.state.account}
                        disabled={this.props.disabled}
                    />
                )}
            </React.Fragment>
        );
    }
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IRootState>(
    state => ({
        strings: state.localization.strings,
        accounts: state.session.dropdowns.accounts.payload,
    }),
    {
        onFetch: (e: QueryParam2<boolean, boolean>) => fetchAccounts(e),
    }
)(AccountDropdown);
