import React from "react";
import { PageLayout } from "../components/PageLayout/PageLayout";
import { NavBar } from "../components/NavBar/NavBar";

class NotFoundPage extends React.Component {
    render() {
        return (
            <PageLayout header={<NavBar canNavigateRoot label="Page not found" />}>
                <div className="page-container">
                    <p>Page not found.</p>
                    <small>404</small>
                </div>
            </PageLayout>
        );
    }
}

export default NotFoundPage;
