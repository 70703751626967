import React from "react";
import styles from "./InfoPanel.module.scss";
import classNames from "classnames";
import { connect } from "react-redux";
import { IRootState } from "../../redux/reducers/root";
import { IStrings } from "../../constants/languageStrings/IStrings";
import { IDashboardData } from "../../model/dashboard/IDashboardData";
import { selectUserFullname } from "../../redux/selectors/selectUserFullname";
import { Balance } from "./Balance";
import { Vacation } from "./Vacation";
import { DateTimeFormatter } from "../../components/DateTimeFormatter";
import PermissionGuard from "../../components/PermissionGuard";
import { permissions } from "../../constants/permissions";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { fetchSelectedUserTicket, setSelectedUser } from "../../redux/actions/userList";
import { fetchToken } from "../../redux/actions/user";
import { fetchDashboardData } from "../../redux/actions/dashboard";

interface IStateProps {
    strings: IStrings;
    fullname?: string;
    data?: IDashboardData;
}

function InfoPanel(props: IStateProps) {
    const dispatch = useAppDispatch();
    const userList = useAppSelector(s => s.session.userList.users);
    const selected = useAppSelector(s => s.session.userList.selected);
    const tokenFetching = useAppSelector(s => s.session.user.isAuthenticating);
    const dataFetching = useAppSelector(s => s.session.dashboard.isFetching);
    const data = useAppSelector(s => s.session.dashboard.data);
    return (
        <div className={styles.panel}>
            <div className={styles.info}>
                <h2 className={classNames(styles.gutterBottom, styles.textPerson)} style={{ display: "flex" }}>
                    {!(userList.length > 1) && <b>{props.fullname}</b>}
                    {userList.length > 1 && (
                        <DropDownList
                            disabled={tokenFetching || dataFetching}
                            style={{ flex: "1", fontFamily: "inherit", fontSize: "inherit" }}
                            textField="fullname"
                            dataItemKey="poradi"
                            value={userList.find(f => f.poradi === selected)}
                            onChange={e => {
                                dispatch(setSelectedUser(e.target.value.poradi));
                                dispatch(fetchSelectedUserTicket(e.target.value.poradi)).then(res =>
                                    dispatch(
                                        fetchToken({
                                            credentials: {
                                                username: res.payload as string,
                                                password: "",
                                            },
                                        })
                                    ).then(() => {
                                        dispatch(fetchDashboardData());
                                    })
                                );
                            }}
                            data={userList}
                        />
                    )}
                </h2>
                {userList.length > 1 && (
                    <div className={styles.gutterBottom}>
                        {props.strings.common.Center + ": " + userList.find(f => f.poradi === selected)?.str_text}
                    </div>
                )}
                <PermissionGuard permission={permissions.dashboard.balance}>
                    {props.data?.balance && (
                        <div className={styles.gutterBottom}>
                            {props.strings.dashboard.Balance + ":"} <Balance />
                        </div>
                    )}
                </PermissionGuard>
                <div className={styles.gutterBottom}>
                    <PermissionGuard permission={permissions.dashboard.vacation}>
                        <Vacation vacation={data?.vacation} defaultName={props.strings.dashboard.VacationBalance} />
                    </PermissionGuard>
                    <PermissionGuard permission={permissions.dashboard.addVacation}>
                        <br />
                        <Vacation
                            vacation={data?.addVacation}
                            defaultName={props.strings.dashboard.AdditionalVacation}
                        />
                    </PermissionGuard>
                    <PermissionGuard permission={permissions.dashboard.addVacation2}>
                        <br />
                        <Vacation
                            vacation={data?.addVacation2}
                            defaultName={props.strings.dashboard.AdditionalVacation2}
                        />
                    </PermissionGuard>
                </div>
                {props.data?.lastPassage && (
                    <div>
                        <span>
                            <DateTimeFormatter value={new Date(props.data?.lastPassage.time)} format="g" />
                        </span>
                        <span> </span>
                        <span>{props.data?.lastPassage.text?.toLocaleUpperCase()}</span>
                    </div>
                )}
            </div>
        </div>
    );
}

export default connect<IStateProps, {}, {}, IRootState>(
    state =>
        ({
            strings: state.localization.strings,
            fullname: selectUserFullname(state),
            data: state.session.dashboard.data,
        } as IStateProps)
)(InfoPanel);
