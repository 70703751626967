export const permissions = {
    app: {
        run: "SelfServiceApp_RunApp",
    },
    // passes: {
    // 	run: "PassesApp_RunApp",
    // },
    // persons: {
    // 	run: "PersonsApp_RunApp",
    // },
    plan: {
        run: "MyPlanSelfServiceApp_RunApp",
        absence: "PlanApp_Absence",
        check: "PlanSelfServiceApp_CheckPlan",
        approve: "PlanSelfServiceApp_ApprovePlan",
        reqNote: "PlanSelfServiceApp_RequiredNote",
    },
    categoryFilter: {
        enable: "SelfServiceApp_CategoryFilter",
    },
    departmentPlan: {
        run: "DepartmentPlanSelfServiceApp_RunApp",
        absence: "SelfServiceApp_InsertAbsence",
    },
    planStates: {
        run: "PlanStatesSelfServiceApp_RunApp",
    },
    presence: {
        run: "PresenceSelfServiceApp_RunApp",
        showDetails: "PresenceSelfServiceApp_DetailVisibility",
        showContacts: "SelfServiceApp_ContactsVisibility",
        showReason: "PresenceSelfServiceApp_AbsenceReason",
    },
    reports: {
        run: "ReportsSelfServiceApp_RunApp",
    },
    myRequests: {
        run: "MyRequestsSelfServiceApp_RunApp",
    },
    requestsForApproval: {
        run: "RequestsApprovalSelfServiceApp_RunApp",
        rejectNote: "SelfServiceApp_RejectNote",
    },
    unavailabilitiesForApproval: {
        run: "UnavasApprovalSelfServiceApp_RunApp",
    },
    terminal: {
        run: "TerminalSelfServiceApp_RunApp",
        gps: "SelfServiceApp_TerminalGPS",
    },
    unavailabilities: {
        run: "MyUnavailabilitiesSelfServiceApp_RunApp",
        edit: "SelfServiceApp_EditUnavalabilities",
    },
    workSheet: {
        attachments: "WorkSheetApp_Attachments",
        run: "MyWSSelfServiceApp_RunApp",
        sign: "SelfServiceApp_SignWS",
    },
    workSheetStates: {
        run: "WorksheetStatesSelfServiceApp_RunApp",
    },
    dashboard: {
        balance: "DashboardSelfServiceApp_BalanceVisibility",
        vacation: "DashboardSelfServiceApp_VacationVisibility",
        addVacation: "DashboardSelfServiceApp_AddVacationVisibility",
        addVacation2: "DashboardSelfServiceApp_AddVacation2Visibility",
    },
    reportsDocuments: {
        ErrorSyntax: "ReportsSelfServiceApp_ErrorSyntax",
        MissingPasses: "ReportsSelfServiceApp_MissingPasses",
        EvidenceZP: "ReportsSelfServiceApp_EvidenceZP",
        MonthStatBalance: "ReportsSelfServiceApp_MonthStatBalance",
        Plan: "ReportsSelfServiceApp_Plan",
        ErrorZP: "ReportsSelfServiceApp_ErrorZP",
        PotencialOvertimes: "ReportsSelfServiceApp_PotencialOvertimes",
        LatePasses: "ReportsSelfServiceApp_LatePasses",
        WorkSheet: "ReportsSelfServiceApp_WorkSheet",
        FirstLastPasses: "ReportsSelfServiceApp_FirstLastPasses",
        DailyAccounts: "ReportsSelfServiceApp_DailyAccounts",
        Overtimes: "ReportsSelfServiceApp_Overtimes",
        VacationStatistics: "ReportsSelfServiceApp_VacationStatistics",
        MissedShifts: "ReportsSelfServiceApp_MissedShifts",
        VacationRemaining: "ReportsSelfServiceApp_VacationRemaining",
    },
    overtime: {
        run: "OvertimeSelfServiceApp_RunApp",
    },
};
