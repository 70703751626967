import React from "react";
import { IStrings } from "../../constants/languageStrings/IStrings";
import { connect } from "react-redux";
import { IRootState } from "../../redux/reducers/root";
import Geolocation from "react-native-geolocation-service";
import { IPOCPT, TerminalConfig } from "../../model/terminal/IPOCPT";
import { fetchSecuredPassesDatasource, insertPass } from "../../redux/actions/terminal";
import { PageLayout } from "../../components/PageLayout/PageLayout";
import { NavBar } from "../../components/NavBar/NavBar";
import { Button } from "@progress/kendo-react-buttons";
import { IBaseMenuItem } from "../../components/NavBar/IBaseMenuItem";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import styles from "../Terminal/Terminal.module.scss";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Slide } from "@progress/kendo-react-animation";
import { NotificationGroup, Notification } from "@progress/kendo-react-notification";
import { ILanguage } from "../../model/localization/ILanguage";
import { Input } from "@progress/kendo-react-inputs";
import { QueryParam3 } from "../../model/CommonQueryParams";
import { IPOPRT } from "../../model/terminal/IPOPRT";
import { RouteChildrenProps } from "react-router-dom";
import { SerializedError } from "@reduxjs/toolkit";
import { SpinnerBox } from "../../components/Spinner/SpinnerBox";
import { ErrorNotification } from "../../components/ErrorNotification";
import { permissions } from "../../constants/permissions";
import classNames from "classnames";
import { selectIxsRef } from "../../redux/selectors/selectIxsRef";

const position = {
    top: 0,
    left: "50%",
    transform: "translateX(-50%)",
};
class Terminal extends React.Component<IProps, IState> {
    private timeUpdateIntervalID?: number;
    private timeOutId?: number;
    private isComponentMounted?: boolean;
    private locationTimeout?: NodeJS.Timer;

    defaultItem: IPOCPT = {
        PRUCHOD_TERM: this.props.strings.terminals.ChoosePass,
        PRITOMNOST: -1,
        PRUCHOD_SMER: 0,
    };
    constructor(props: IProps) {
        super(props);
        this.state = {
            dropdownVal: this.defaultItem,
            locatingDone: false,
            passToInsert: undefined,
            note: "",
            curTime: new Date().toLocaleString(),
            dialog: {
                settings: false,
            },
            notification: {
                visible: false,
                text: "",
                success: true,
            },
        };
    }

    private createMenuItems = () => {
        const strings = this.props.strings;
        const items: IBaseMenuItem[] = [{ id: 1, content: strings.terminals.Settings }];

        return items;
    };

    private handleMenuItemClick = (item: IBaseMenuItem) => {
        switch (item.id) {
            case 1:
                this.openSettingsDialog();
                break;
            default:
                item.id && console.warn("Unhandled menu item: ", item);
        }
    };

    async openSettingsDialog() {
        let pomConfig: TerminalConfig = Object.create(this.state.oldConfig!);
        this.setState({
            dialog: {
                settings: true,
            },
            newConfig: pomConfig,
        });
    }

    async componentDidMount() {
        this.props.onLoadIpopts && (await this.props.onLoadIpopts());
        let buttonsConf: TerminalConfig | null = this.convertToConfiguration(
            localStorage.getItem("terminalConfiguration")
        );
        this.setState({ oldConfig: buttonsConf });
        this.timeUpdateIntervalID = setInterval(() => {
            this.setState({
                curTime: new Date().toLocaleString(),
            });
        }, 1000) as unknown as number;
        if (this.props.isGPSPositionAllowed && window.isSecureContext) {
            this.generateGPS();
        } else {
            this.setState({ locatingDone: true });
        }
        this.isComponentMounted = true;
    }

    componentWillUnmount() {
        clearInterval(this.timeUpdateIntervalID);
        clearTimeout(this.timeOutId);
        this.isComponentMounted = false;
    }

    public ConfigConvertToString(input: TerminalConfig | null): string {
        if (input !== null) {
            return JSON.stringify(input);
        }
        return "";
    }

    public getGPSLocationString(): string {
        return this.state.position != null
            ? this.state.position.lat.toString().substring(0, 11) +
                  "," +
                  this.state.position.lng.toString().substring(0, 11)
            : "";
    }

    public convertToConfiguration(input: string | null): TerminalConfig {
        if (input === "" || input === null) {
            let config: TerminalConfig = {
                button1: this.props.data.find(ipo => ipo.PRITOMNOST === 1),
                button2: this.props.data.find(ipo => ipo.PRITOMNOST === 21),
            };
            return config;
        } else {
            let config: TerminalConfig = this.ControlConfigPermissions(JSON.parse(input));

            config.button1 = this.props.data.find(ipo => ipo.PRITOMNOST === 1);
            config.button2 = this.props.data.find(ipo => ipo.PRITOMNOST === 21);
            return config;
        }
    }

    private ControlConfigPermissions(savedConfig: TerminalConfig): TerminalConfig {
        savedConfig.button3 = this.props.data.some(ipo => ipo.PRITOMNOST === savedConfig.button3?.PRITOMNOST)
            ? savedConfig.button3
            : undefined;
        savedConfig.button4 = this.props.data.some(ipo => ipo.PRITOMNOST === savedConfig.button4?.PRITOMNOST)
            ? savedConfig.button4
            : undefined;
        savedConfig.button5 = this.props.data.some(ipo => ipo.PRITOMNOST === savedConfig.button5?.PRITOMNOST)
            ? savedConfig.button5
            : undefined;
        savedConfig.button6 = this.props.data.some(ipo => ipo.PRITOMNOST === savedConfig.button6?.PRITOMNOST)
            ? savedConfig.button6
            : undefined;
        return savedConfig;
    }

    async onDialogClose(operation: number): Promise<void> {
        if (operation === 1) {
            let config: TerminalConfig = this.state.oldConfig!;
            config.button1 = this.props.data.find(ipo => ipo.PRITOMNOST === 1);
            config.button2 = this.props.data.find(ipo => ipo.PRITOMNOST === 21);
            config.button3 =
                this.state.newConfig !== null && this.state.newConfig?.button3 !== null
                    ? this.state.newConfig?.button3
                    : undefined;
            config.button4 =
                this.state.newConfig !== null && this.state.newConfig?.button4 !== null
                    ? this.state.newConfig?.button4
                    : undefined;
            config.button5 =
                this.state.newConfig !== null && this.state.newConfig?.button5 !== null
                    ? this.state.newConfig?.button5
                    : undefined;
            config.button6 =
                this.state.newConfig !== null && this.state.newConfig?.button6 !== null
                    ? this.state.newConfig?.button6
                    : undefined;

            let pomConfig: TerminalConfig = JSON.parse(JSON.stringify(config));
            localStorage.setItem("terminalConfiguration", this.ConfigConvertToString(pomConfig));
            this.setState({
                dialog: {
                    settings: false,
                },
                oldConfig: pomConfig,
            });
        } else {
            this.setState({
                dialog: {
                    settings: false,
                },
            });
        }
    }

    handleChange = (e: DropDownListChangeEvent, i: number) => {
        let config: TerminalConfig = this.state.newConfig!;
        const value = e.target.value.$id > -1 ? e.target.value : undefined;
        if (i === 3) config.button3 = value;
        if (i === 4) config.button4 = value;
        if (i === 5) config.button5 = value;
        if (i === 6) config.button6 = value;
        this.setState({
            newConfig: config,
        });
    };

    private getButtonStyle(direction: number | null | undefined): string {
        if (direction == null) return styles.grayColor;
        return direction === 1 ? styles.greenColor : direction === 2 ? styles.redColor : styles.grayColor;
    }

    public setColorClassNameBasedOnValue(config: TerminalConfig | undefined | null, index: number): string {
        if (config != null) {
            return index === 3
                ? config.button3?.PRUCHOD_SMER === 2
                    ? styles.redColor
                    : config.button3?.PRUCHOD_SMER === 1
                    ? styles.greenColor
                    : ""
                : index === 4
                ? config.button4?.PRUCHOD_SMER === 2
                    ? styles.redColor
                    : config.button4?.PRUCHOD_SMER === 1
                    ? styles.greenColor
                    : ""
                : index === 5
                ? config.button5?.PRUCHOD_SMER === 2
                    ? styles.redColor
                    : config.button5?.PRUCHOD_SMER === 1
                    ? styles.greenColor
                    : ""
                : index === 6
                ? config.button6?.PRUCHOD_SMER === 2
                    ? styles.redColor
                    : config.button6?.PRUCHOD_SMER === 1
                    ? styles.greenColor
                    : ""
                : "";
        }
        return "";
    }

    public generateGPS(): void {
        Geolocation.getCurrentPosition(
            position => {
                if (this.isComponentMounted) {
                    this.setState({
                        position: {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        },
                        locatingDone: true,
                    });
                    if (this.state.passToInsert) {
                        this.insertPass(this.state.passToInsert);
                    }
                }
            },
            error => {
                // See error code charts below.
                console.log(error.code, error.message);
                if (this.isComponentMounted) {
                    this.setState({ locatingDone: true });
                    if (this.state.passToInsert) {
                        this.insertPass(this.state.passToInsert);
                    }
                }
                return error.code;
            },
            { enableHighAccuracy: true, timeout: 15000, maximumAge: 10000 }
        );
    }

    public insertPass(pass: IPOCPT | null | undefined) {
        if (this.state.locatingDone) {
            if (this.locationTimeout) clearTimeout(this.locationTimeout);
            this.setState({ passToInsert: undefined });
            this.generateGPSAndInsertPass(pass);
        } else {
            this.setState({ passToInsert: pass });
            this.locationTimeout = setTimeout(() => {
                this.setState({ passToInsert: undefined });
                this.generateGPSAndInsertPass(pass);
            }, 5000);
        }
    }

    public async generateGPSAndInsertPass(pass: IPOCPT | null | undefined) {
        if (pass != null) {
            let ipoprt: IPOPRT = {
                IXS_REF: this.props.ixsRef ? this.props.ixsRef : "",
                IXS_REF_ZMENA: this.props.ixsRef ? this.props.ixsRef : "",
                PRITOMNOST: pass.PRITOMNOST,
                CAS: new Date(),
                TERMINAL: -3,
                PLATNOST: 1,
                POZNAMKA: this.state.note,
                GPS: this.getGPSLocationString(),
            };
            let prt: QueryParam3<string, string, string> = { Value: JSON.stringify(ipoprt) };
            await this.props.onInsertPass(prt);
            let notify: string = "";
            let success: boolean = true;
            if (this.props.insertResult) {
                notify =
                    this.props.lang.code === "en"
                        ? pass.PRUCHOD_TERM + " " + this.props.strings.terminals.NotificationEnText
                        : this.props.strings.terminals.NotificationText + " " + pass.PRUCHOD_TERM;
            } else {
                notify = this.props.strings.terminals.NotificationErrorText;
                success = false;
            }
            this.setState({
                notification: {
                    visible: true,
                    text: notify,
                    success: success,
                },
                note: "",
            });
            this.timeOutId = setTimeout(() => {
                this.setState({ notification: { visible: false } });
            }, 3000) as unknown as number;
        }
    }

    public handleDropdownChange = (event: DropDownListChangeEvent) => {
        if ((event.value as IPOCPT).PRITOMNOST !== -1) {
            this.insertPass(event.value);
            this.setState({ dropdownVal: this.defaultItem });
        }
    };

    public render() {
        return (
            <PageLayout
                header={
                    <NavBar
                        canNavigateRoot
                        label={this.props.strings.dashboard.OnlineTerminal}
                        menu={{
                            items: this.createMenuItems(),
                            onItemClick: this.handleMenuItemClick,
                        }}
                    >
                        <Button onClick={() => this.props.history.push("/lastPasses")}>
                            {this.props.strings.lastPasses.TitleLP}
                        </Button>
                    </NavBar>
                }
            >
                <NotificationGroup className={styles.z} style={position}>
                    <Slide direction={!this.state.notification.visible ? "up" : "down"}>
                        {this.state.notification.visible && (
                            <Notification
                                type={{ style: this.state.notification.success ? "success" : "error", icon: true }}
                                closable={true}
                                onClose={() => this.setState({ notification: { visible: false } })}
                            >
                                <span>{this.state.notification.text}</span>
                            </Notification>
                        )}
                    </Slide>
                </NotificationGroup>

                {this.props.isFetchingPasses || this.props.isFetchingResult || this.state.passToInsert ? (
                    <SpinnerBox></SpinnerBox>
                ) : (
                    <>
                        {this.props.passesFetchError ? (
                            <ErrorNotification error={this.props.passesFetchError} />
                        ) : (
                            <div className={styles.layout}>
                                <label className={styles.timer}>{this.state.curTime}</label>
                                <div className={styles.row}>
                                    {this.state.oldConfig != null && this.state.oldConfig.button1 != null && (
                                        <Button
                                            className={styles.greenColor}
                                            onClick={() =>
                                                this.insertPass(
                                                    this.state.oldConfig != null ? this.state.oldConfig.button1 : null
                                                )
                                            }
                                        >
                                            {this.state.oldConfig != null && this.state.oldConfig.button1 != null
                                                ? this.state.oldConfig.button1.PRUCHOD_TERM
                                                : ""}
                                        </Button>
                                    )}
                                    {this.state.oldConfig != null && this.state.oldConfig.button2 != null && (
                                        <Button
                                            className={styles.redColor}
                                            onClick={() =>
                                                this.insertPass(
                                                    this.state.oldConfig != null ? this.state.oldConfig.button2 : null
                                                )
                                            }
                                        >
                                            {this.state.oldConfig != null && this.state.oldConfig.button2 != null
                                                ? this.state.oldConfig.button2.PRUCHOD_TERM
                                                : ""}
                                        </Button>
                                    )}
                                </div>
                                <div className={styles.row}>
                                    <Button
                                        className={this.setColorClassNameBasedOnValue(this.state.oldConfig, 3)}
                                        onClick={() =>
                                            this.insertPass(
                                                this.state.oldConfig != null ? this.state.oldConfig.button3 : null
                                            )
                                        }
                                    >
                                        {this.state.oldConfig != null && this.state.oldConfig.button3 != null
                                            ? this.state.oldConfig.button3.PRUCHOD_TERM
                                            : ""}
                                    </Button>
                                    <Button
                                        className={this.setColorClassNameBasedOnValue(this.state.oldConfig, 4)}
                                        onClick={() =>
                                            this.insertPass(
                                                this.state.oldConfig != null ? this.state.oldConfig.button4 : null
                                            )
                                        }
                                    >
                                        {this.state.oldConfig != null && this.state.oldConfig.button4 != null
                                            ? this.state.oldConfig.button4.PRUCHOD_TERM
                                            : ""}
                                    </Button>
                                </div>
                                <div className={styles.row}>
                                    <Button
                                        className={this.setColorClassNameBasedOnValue(this.state.oldConfig, 5)}
                                        onClick={() =>
                                            this.insertPass(
                                                this.state.oldConfig != null ? this.state.oldConfig.button5 : null
                                            )
                                        }
                                    >
                                        {this.state.oldConfig != null && this.state.oldConfig.button5 != null
                                            ? this.state.oldConfig.button5.PRUCHOD_TERM
                                            : ""}
                                    </Button>
                                    <Button
                                        className={this.setColorClassNameBasedOnValue(this.state.oldConfig, 6)}
                                        onClick={() =>
                                            this.insertPass(
                                                this.state.oldConfig != null ? this.state.oldConfig.button6 : null
                                            )
                                        }
                                    >
                                        {this.state.oldConfig != null && this.state.oldConfig.button6 != null
                                            ? this.state.oldConfig.button6.PRUCHOD_TERM
                                            : ""}
                                    </Button>
                                </div>
                                <div className={styles.dropdownlist}>
                                    <DropDownList
                                        data={this.props.data?.slice(1)}
                                        textField="PRUCHOD_TERM"
                                        dataItemKey="PRITOMNOST"
                                        value={this.defaultItem}
                                        onChange={this.handleDropdownChange}
                                    ></DropDownList>
                                </div>
                                <div>
                                    <Input
                                        label={this.props.strings.unavailability.NoteComment}
                                        value={this.state.note}
                                        onChange={e => this.setState({ note: e.value })}
                                    />
                                </div>
                            </div>
                        )}
                    </>
                )}
                {this.state.dialog.settings && (
                    <Dialog
                        title={
                            <div className={styles.dialogTitle}>
                                <label>{this.props.strings.terminals.Settings}</label>
                            </div>
                        }
                        width="100%"
                        closeIcon={false}
                        className={styles.dialog}
                        contentStyle={{ padding: "1px" }}
                    >
                        <div className={styles.settingsLayout}>
                            <div className={styles.row}>
                                {this.state.newConfig != null && this.state.newConfig.button1 != null && (
                                    <div className={styles.settingsDropdownlist}>
                                        <Button className={styles.greenColor}>
                                            {this.state.newConfig != null && this.state.newConfig.button1 != null
                                                ? this.state.newConfig.button1.PRUCHOD_TERM
                                                : ""}
                                        </Button>
                                    </div>
                                )}
                                {this.state.newConfig != null && this.state.newConfig.button2 != null && (
                                    <div className={styles.settingsDropdownlist}>
                                        <Button className={styles.redColor}>
                                            {this.state.newConfig != null && this.state.newConfig.button2 != null
                                                ? this.state.newConfig.button2.PRUCHOD_TERM
                                                : ""}
                                        </Button>
                                    </div>
                                )}
                            </div>
                            <div className={styles.row}>
                                <div
                                    className={classNames(
                                        styles.settingsDropdownlist,
                                        this.getButtonStyle(this.state.newConfig?.button3?.PRUCHOD_SMER)
                                    )}
                                >
                                    <DropDownList
                                        data={this.props.data}
                                        textField="PRUCHOD_TERM"
                                        dataItemKey="PRITOMNOST"
                                        value={
                                            this.state.newConfig != null && this.state.newConfig.button3
                                                ? this.state.newConfig.button3
                                                : ""
                                        }
                                        onChange={change => this.handleChange(change, 3)}
                                    ></DropDownList>
                                </div>
                                <div
                                    className={classNames(
                                        styles.settingsDropdownlist,
                                        this.getButtonStyle(this.state.newConfig?.button4?.PRUCHOD_SMER)
                                    )}
                                >
                                    <DropDownList
                                        data={this.props.data}
                                        textField="PRUCHOD_TERM"
                                        dataItemKey="PRITOMNOST"
                                        value={
                                            this.state.newConfig != null && this.state.newConfig.button4
                                                ? this.state.newConfig.button4
                                                : ""
                                        }
                                        onChange={change => this.handleChange(change, 4)}
                                    ></DropDownList>
                                </div>
                            </div>
                            <div className={styles.row}>
                                <div
                                    className={classNames(
                                        styles.settingsDropdownlist,
                                        this.getButtonStyle(this.state.newConfig?.button5?.PRUCHOD_SMER)
                                    )}
                                >
                                    <DropDownList
                                        data={this.props.data}
                                        textField="PRUCHOD_TERM"
                                        dataItemKey="PRITOMNOST"
                                        value={
                                            this.state.newConfig != null && this.state.newConfig.button5
                                                ? this.state.newConfig.button5
                                                : ""
                                        }
                                        onChange={change => this.handleChange(change, 5)}
                                    ></DropDownList>
                                </div>
                                <div
                                    className={classNames(
                                        styles.settingsDropdownlist,
                                        this.getButtonStyle(this.state.newConfig?.button6?.PRUCHOD_SMER)
                                    )}
                                >
                                    <DropDownList
                                        data={this.props.data}
                                        textField="PRUCHOD_TERM"
                                        dataItemKey="PRITOMNOST"
                                        value={
                                            this.state.newConfig != null && this.state.newConfig.button6
                                                ? this.state.newConfig.button6
                                                : ""
                                        }
                                        onChange={change => this.handleChange(change, 6)}
                                    ></DropDownList>
                                </div>
                            </div>
                        </div>
                        <DialogActionsBar>
                            <Button onClick={() => this.onDialogClose(0)}>{this.props.strings.common.Close}</Button>
                            <Button onClick={() => this.onDialogClose(1)} style={{ color: "green" }}>
                                {this.props.strings.common.Save}
                            </Button>
                        </DialogActionsBar>
                    </Dialog>
                )}
            </PageLayout>
        );
    }
}

interface IOwnProps {
    data: IPOCPT[];
    insertResult: boolean;

    isFetchingPasses: boolean;
    isFetchingResult: boolean;

    isGPSPositionAllowed: boolean;

    passesFetchError: SerializedError;
}

interface IStateProps {
    strings: IStrings;
    lang: ILanguage;
    ixsRef?: string;
}

interface IState {
    position?: {
        lat: number;
        lng: number;
    };
    passToInsert: IPOCPT | null | undefined;
    locatingDone: boolean;
    curTime: string;
    note: string;
    dropdownVal: IPOCPT;
    oldConfig?: TerminalConfig | null;
    newConfig?: TerminalConfig | null;
    dialog: {
        settings: boolean;
    };
    notification: {
        visible: boolean;
        text?: string;
        success?: boolean;
    };
}

interface IDispatchProps {
    onLoadIpopts: () => Promise<void>;
    onInsertPass: (e: QueryParam3<string, string, string>) => Promise<boolean>;
}

type IProps = IOwnProps & IStateProps & IDispatchProps & IState & RouteChildrenProps;

export default connect<IStateProps, IDispatchProps, IOwnProps, IRootState>(
    state => ({
        strings: state.localization.strings,
        lang: state.localization.language,
        ixsRef: selectIxsRef(state),
        data: state.session.terminals.passes,
        insertResult: state.session.insertPass.result,

        isFetchingPasses: state.session.terminals.isFetching,
        isFetchingResult: state.session.insertPass.isFetching,

        isGPSPositionAllowed:
            !state.session.user.token?.userDisabledActions.some(p => p === permissions.terminal.gps) ?? false,

        passesFetchError: state.session.terminals.error,
    }),
    {
        onLoadIpopts: () => fetchSecuredPassesDatasource() as any,
        onInsertPass: (e: QueryParam3<string, string, string>) => insertPass(e) as any,
    }
)(Terminal);
