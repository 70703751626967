import { SerializedError, createReducer } from "@reduxjs/toolkit";
import { fetchToken, clearError, logout } from "../actions/user";
import { INormalizedToken } from "../../model/security/tokens";
import { setSelectedUser } from "../actions/userList";

export interface IUserState {
    isAuthenticating: boolean;
    requestID?: string;
    isAuthenticated: boolean;
    token?: INormalizedToken;
    error?: SerializedError;
}

export const userReducer = createReducer<IUserState>(
    {
        isAuthenticating: false,
        isAuthenticated: false,
    },
    builder =>
        builder
            .addCase(fetchToken.pending, (state, action) => ({
                ...state,
                isAuthenticating: true,
                requestID: action.meta.requestId,
                error: undefined,
            }))
            .addCase(fetchToken.fulfilled, (state, action) =>
                state.requestID === action.meta.requestId
                    ? {
                          ...state,
                          isAuthenticating: false,
                          isAuthenticated: true,
                          token: action.payload,
                          error: undefined,
                      }
                    : state
            )
            .addCase(fetchToken.rejected, (state, action) =>
                state.requestID === action.meta.requestId
                    ? {
                          ...state,
                          isAuthenticating: false,
                          isAuthenticated: false,
                          token: undefined,
                          error: action.error,
                      }
                    : state
            )
            .addCase(logout, (state, action) => ({
                ...state,
                isAuthenticated: false,
                token: undefined,
            }))
            .addCase(clearError, (state, action) => ({
                ...state,
                error: undefined,
            }))
            .addCase(setSelectedUser, (state, action) => ({
                ...state,
                isAuthenticating: true,
            }))
);
